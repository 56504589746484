.link {
font-family: 'Poppins', sans-serif;;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(241, 245, 248, 1)!important;
}
.droparrow{
    margin-top: 8px;
    margin-left: 4px;
    cursor: pointer;
}
